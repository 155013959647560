const {
  checkPermissions,
  checkPermission,
} = require('@/utils/saveDataToLocal');
export const SideBar = {
  ACCOUNTANT: [
    {
      name: 'Thu chi tiền mặt',
      icon: 'menu-icon fas fa-money-bill',
      route: '/accounting/transactions/cash',
      show: checkPermissions(['TRANSACTION_VIEW']),
    },
    {
      name: 'Thu chi ngân hàng',
      icon: 'menu-icon fas fa-university',
      route: '/accounting/transactions/bank',
      show: checkPermissions(['TRANSACTION_VIEW']),
    },
    {
      name: 'Tổng hợp thu chi',
      icon: 'menu-icon fas fa-receipt',
      route: '/accounting/transactions/cashbook',
      show: checkPermissions(['TRANSACTION_VIEW']),
    },
    {
      name: 'Công nợ',
      icon: 'menu-icon fas fa-hand-holding-usd',
      route: '#',
      show: checkPermissions([
        'DEBT_INSTALLMENT_VIEW',
        'DEBT_CREDIT_VIEW',
        'DEBT_PROVIDER_VIEW',
      ]),
      submenu: [
        {
          name: 'Công nợ nhập hàng',
          icon: 'menu-icon fas fa-shipping-fast',
          route: '/accounting/debts/purchase-order',
          show: false,
        },
        {
          name: 'Công nợ nhà cung cấp',
          icon: 'menu-icon fas fa-shipping-fast',
          route: '/accounting/debts/providers',
          show: checkPermissions(['DEBT_PROVIDER_VIEW']),
        },
        {
          name: 'Hạn thanh toán nhà cung cấp',
          icon: 'menu-icon far fa-calendar-alt',
          route: '/accounting/debts/supplierduedate',
          show: checkPermissions(['DEBT_PROVIDER_VIEW']),
        },
        {
          name: 'Dịch vụ trả góp',
          icon: 'menu-icon fas fa-file-signature',
          route: '/accounting/debts/installments',
          show: checkPermissions(['DEBT_INSTALLMENT_VIEW']),
        },
        {
          name: 'Trả góp hóa đơn',
          icon: 'menu-icon far fa-calendar-alt',
          route: '/accounting/debts/items',
          show: checkPermissions(['DEBT_INSTALLMENT_VIEW']),
        },
        {
          name: 'Trả góp đơn hàng',
          icon: 'menu-icon far fa-calendar-alt',
          route: '/accounting/debts/installment-orders',
          show: checkPermissions(['DEBT_INSTALLMENT_VIEW']),
        },
        {
          name: 'Quẹt thẻ hóa đơn',
          icon: 'menu-icon far fa-calendar-alt',
          route: '/accounting/debts/credits',
          show: checkPermissions(['DEBT_CREDIT_VIEW']),
        },
        {
          name: 'Quẹt thẻ đơn hàng',
          icon: 'menu-icon far fa-calendar-alt',
          route: '/accounting/debts/credit-orders',
          show: checkPermissions(['DEBT_CREDIT_VIEW']),
        },
        {
          name: 'Phí ship đơn hàng',
          icon: 'menu-icon far fa-calendar-alt',
          route: '/accounting/debts/ship-fee-orders',
          show: checkPermissions(['DEBT_CREDIT_VIEW']),
        },
      ],
    },
    {
      name: 'Bút toán',
      icon: 'menu-icon fas fa-scroll',
      route: '#',
      show: checkPermissions(['TRANSACTION_VIEW']),
      submenu: [
        {
          name: 'Tất cả bút toán',
          icon: 'menu-icon fas fa-file-signature',
          route: '/accounting/transactions/all',
          show: checkPermissions(['TRANSACTION_VIEW']),
        },
      ],
    },
    {
      name: 'Tài khoản kế toán',
      icon: 'menu-icon fas fa-file-invoice-dollar',
      route: '/accounting/accounts',
      show: checkPermissions(['ACCOUNTANT_VIEW']),
    },
    {
      name: 'Dịch vụ trả góp',
      icon: 'menu-icon fas fa-file-signature',
      route: '/accounting/installments',
      show: checkPermissions(['INSTALLMENT_VIEW']),
    },
    {
      name: 'Danh mục thu chi',
      icon: 'menu-icon fas fa-file-signature',
      route: '/transaction-categories',
      show: checkPermissions(['ACCOUNTANT_VIEW']),
    },
    {
      name: 'Giải ngân công nợ',
      icon: 'menu-icon fas fa-file-signature',
      route: '/disbursement',
      show: true,
    },
    {
      name: 'Phiếu yêu cầu duyệt chi',
      icon: 'menu-icon fas fa-file-signature',
      route: '/approval',
      show: checkPermission('APPROVAL_VIEW'),
    },
    {
      name: 'Lịch sử thanh toán',
      icon: 'menu-icon fas fa-file-signature',
      route: '/qr-code-history',
      show: true,
    },
    {
      name: 'Trạng thái đồng bộ với Fast',
      icon: 'menu-icon fas fa-address-book',
      route: '/fast-sync',
      show: true,
    },
  ],
  PROFILE: [
    {
      name: 'Đổi mật khẩu',
      icon: 'menu-icon fas fa-key',
      route: '/profile/change-password',
      show: true,
    },
    {
      name: 'Chuyển cửa hàng',
      icon: 'menu-icon fas fa-store',
      route: '/profile/change-store',
      show: true,
    },
  ],
  REPORT: [
    {
      name: 'Kế toán',
      icon: 'menu-icon fas fa-file-invoice-dollar',
      route: '#',
      show: true,
      submenu: [
        {
          name: 'Thu chi theo cửa hàng',
          icon: 'menu-icon fas fa-store',
          route: '/reports/accounting/cash',
          show: true,
        },
        {
          name: 'Tổng tiền theo cửa hàng',
          icon: 'menu-icon fas fa-money-bill-alt',
          route: '/reports/accounting/money',
          show: true,
        },
      ],
    },
    {
      name: 'Doanh thu',
      icon: 'menu-icon fas fa-file-invoice-dollar',
      route: '#',
      show: true,
      submenu: [
        {
          name: 'Theo sản phẩm',
          icon: 'menu-icon fas fa-store',
          route: '/revenue-report/by-product',
          show: true,
        },
      ],
    },
    {
      name: 'Hoa hồng NCH',
      icon: 'menu-icon fas fa-file-invoice-dollar',
      route: '#',
      show: true,
      submenu: [
        {
          name: 'Báo cáo tổng hợp',
          icon: 'menu-icon fas fa-store',
          route: '/reward-reports/overview',
          show: checkPermissions(['RR_VIEW_ALL']),
        },
        {
          name: 'Báo cáo chi tiết',
          icon: 'menu-icon fas fa-money-bill-alt',
          route: '/reward-reports/detail',
          show: checkPermissions(['RR_VIEW_ALL']),
        },
        {
          name: 'Báo cáo chi tiết theo nhân viên',
          icon: 'menu-icon fas fa-money-bill-alt',
          route: '/reward-reports/detail-by-employee',
          show: true,
        },
        {
          name: 'DS hóa đơn theo CH',
          icon: 'menu-icon fas fa-money-bill-alt',
          route: '/reward-reports/list-bill-by-store',
          show: checkPermissions(['RR_VIEW_ALL']),
        },
        {
          name: 'DS hóa đơn theo NV',
          icon: 'menu-icon fas fa-money-bill-alt',
          route: '/reward-reports/list-bill-by-employee',
          show: true,
        },
      ],
    },
    {
      name: 'Hoa hồng CSKH',
      icon: 'menu-icon fas fa-file-invoice-dollar',
      route: '/reward-cr-reports/overview',
      show: checkPermissions(['RR_VIEW_ALL', 'RR_VIEW_BILL']),
    },
    {
      name: 'Hoa hồng NPK',
      icon: 'menu-icon fas fa-file-invoice-dollar',
      route: '/reward-accessory-reports',
      show: checkPermissions(['RR_VIEW_ALL']),
    },
    {
      name: 'Hoa hồng NHĐT',
      icon: 'menu-icon fas fa-file-invoice-dollar',
      route: '#',
      show: checkPermissions(['RR_VIEW_ALL']),
      submenu: [
        {
          name: 'Báo cáo Tháng',
          icon: 'menu-icon fas fa-money-bill-alt',
          route: '/reward-phone-reports/month-reports',
          show: true,
        },
        {
          name: 'Báo cáo Quý',
          icon: 'menu-icon fas fa-money-bill-alt',
          route: '/reward-phone-reports/quater-reports',
          show: false,
        },
      ],
    },
    {
      name: 'Sản phẩm XNK',
      icon: 'menu-icon fas fa-file-invoice-dollar',
      route: '/product-stockslip-reports',
      show: checkPermissions(['RR_VIEW_ALL']),
    },
    {
      name: 'Sản phẩm bán chạy',
      icon: 'menu-icon fas fa-file-invoice-dollar',
      route: '/product-sale-reports',
      show: checkPermissions(['RR_VIEW_ALL']),
    },
    {
      name: 'Tổng hợp SP IMEI đã bán',
      icon: 'menu-icon fas fa-file-invoice-dollar',
      route: '/product-imei-sale-reports',
      show: checkPermissions(['RR_VIEW_ALL']),
    },
    {
      name: 'Báo cáo kích hoạt IMEI',
      icon: 'menu-icon fa fa-check-circle',
      route: '/active-imei-report',
      show: checkPermissions(['IMEI_ACTIVE_REPORT_VIEW']),
    },
    {
      name: 'Phiếu không phục vụ được',
      icon: 'menu-icon fas fa-file-invoice-dollar',
      route: '/order-cant-serve',
      show: true,
    },
    {
      name: 'Thống kê lượt phục vụ',
      icon: 'menu-icon fas fa-file-invoice-dollar',
      route: '/customer-serve',
      show: true,
    },
    {
      name: 'Báo cáo XNT',
      icon: 'menu-icon fas fa-file-invoice-dollar',
      route: '/report-inventory-summaries',
      show: checkPermissions(['PRODUCT_ORIGINAL_PRICE_VIEW']),
    },
    {
      name: 'POSM',
      icon: 'menu-icon fas fa-file',
      route: '#',
      show: checkPermissions([
        'CAMPAIGN_VIEW',
        'POSM_FORM_VIEW',
        'POSM_REPORT_INSERT',
        'POSM_REPORT_VIEW',
      ]),
      submenu: [
        {
          name: 'Quản lý Campaign',
          icon: 'menu-icon fas fa-file',
          route: '/posm/campaigns',
          show: checkPermissions(['CAMPAIGN_VIEW', 'CAMPAIGN_EXCEL_EXPORT']),
        },
        {
          name: 'Quản lý Form POSM',
          icon: 'menu-icon fas fa-file',
          route: '/posm/overview',
          show: checkPermissions(['POSM_FORM_VIEW']),
        },
        {
          name: 'Báo cáo POSM',
          icon: 'menu-icon fas fa-file',
          route: '/posm/fill-in',
          show: checkPermissions(['POSM_REPORT_INSERT']),
        },
        {
          name: 'Danh sách các Form POSM đã điền',
          icon: 'menu-icon fas fa-file',
          route: '/posm/submissions',
          show: checkPermissions([
            'POSM_FORM_SUBMISSION_VIEW',
            'POSM_FORM_SUBMISSION_AREA_VIEW',
          ]),
        },
      ],
    },
    {
      name: 'Tuổi kho',
      icon: 'menu-icon fas fa-hourglass-end',
      route: '#',
      show: true,
      submenu: [
        {
          name: 'Theo IMEI',
          icon: 'menu-icon fa fa-mobile',
          route: '/storage-time-reports/by-imei',
          show: true,
        },
        {
          name: 'Theo NH máy',
          icon: 'menu-icon fa fa-mobile',
          route: '/storage-time-reports/by-cate-imei',
          show: true,
        },
      ],
    },
    {
      name: 'Phiếu yêu cầu hỗ trợ',
      icon: 'menu-icon far fa-sticky-note',
      route: '#',
      show: true,
      submenu: [
        {
          name: 'Danh sách',
          icon: 'menu-icon fas fa-list-ul',
          route: '/ticket',
          show: true,
          // show: checkPermission('CUSTOMER_VIEW'),
        },
        {
          name: 'Cấu hình',
          icon: 'menu-icon fa fa-cog',
          route: '/ticket-setting',
          show: checkPermission('TICKET_SETTING'),
        },
      ],
    },
    {
      name: 'Báo cáo ngành hàng',
      icon: 'menu-icon fas fa-file',
      route: '#',
      show: checkPermissions([
        'BI_REVENUE_VIEW',
        'BI_SALES_CAPACITY_INVENTORY_VIEW'
      ]),
      submenu: [
        {
          name: 'Doanh thu',
          icon: 'menu-icon fas fa-store',
          route: '/bi-report/revenue/common',
          show: checkPermission('BI_REVENUE_VIEW'),
        },
        {
          name: 'Sức bán & Tồn kho',
          icon: 'menu-icon fas fa-store',
          route: '/bi-report/sales-capacity-inventory',
          show: checkPermission('BI_SALES_CAPACITY_INVENTORY_VIEW'),
        },
        {
          name: 'Sức bán theo Phân khúc giá',
          icon: 'menu-icon fas fa-store',
          route: '/bi-report/price-segment',
          show: true,
        }
      ],
    },
    {
      name: 'Lịch sử xuất excel',
      icon: 'menu-icon fa-solid fa-table-list',
      route: '/report-excel',
      show: checkPermission('REPORT_HISTORY_VIEW'),
    },
  ],
  PRODUCT: [
    {
      name: 'Sản phẩm',
      icon: 'menu-icon flaticon-tool',
      route: '/products',
      show: checkPermissions(['PRODUCT_VIEW']),
    },
    {
      name: 'Danh mục sản phẩm',
      icon: 'menu-icon flaticon2-menu-1',
      route: '/categories',
      show: checkPermissions(['CATE_VIEW']),
    },
    {
      name: 'Danh mục nội bộ',
      icon: 'menu-icon flaticon2-menu-1',
      route: '/external-categories',
      show: checkPermissions(['CATE_VIEW']),
    },
    {
      name: 'Tồn kho',
      icon: 'menu-icon flaticon2-supermarket',
      route: '#',
      show: checkPermission('STOCK_VIEW'),
      submenu: [
        {
          name: 'Tồn kho',
          icon: 'menu-icon flaticon2-supermarket',
          route: '/productStock',
          show: checkPermission('STORE_VIEW'),
        },
        {
          name: 'Kiểm tra tồn kho',
          icon: 'menu-icon fa fa-warehouse',
          route: '/inventory-check',
          show: checkPermission('STORE_VIEW'),
        },
      ],
    },
    {
      name: 'Thuộc tính sản phẩm',
      icon: 'menu-icon flaticon2-tools-and-utensils',
      route: '/properties',
      show: checkPermissions(['PROPERTY_VIEW']),
    },
    {
      name: 'Thương hiệu',
      icon: 'menu-icon flaticon-bell-1',
      route: '/brands',
      show: checkPermissions(['BRAND_VIEW']),
    },
    {
      name: 'Nhà cung cấp',
      icon: 'menu-icon flaticon2-delivery-truck',
      route: '/providers',
      show: checkPermissions(['PROVIDER_VIEW']),
    },
    {
      name: 'Danh sách IMEI',
      icon: 'menu-icon fas fa-barcode',
      route: '/imeis',
      show: checkPermissions(['PRODUCT_VIEW']),
    },
    {
      name: 'Tra cứu IMEI cũ',
      icon: 'menu-icon fas fa-barcode',
      route: '/old-imeis',
      show: checkPermissions(['PRODUCT_VIEW']),
    },
    {
      name: 'Lịch sử IMEI',
      icon: 'menu-icon fas fa-history',
      route: '/imei-histories',
      show: checkPermissions(['IMEI_HISTORY_VIEW']),
    },
    {
      name: 'Thông tin bảo hành',
      icon: 'menu-icon fas fa-tools',
      route: '/warranty-info',
      show: checkPermissions(['PRODUCT_VIEW']),
    },
    {
      name: 'Thời gian lưu kho',
      icon: 'menu-icon fas fa-clock',
      route: '/storage-time',
      show: checkPermissions(['PRODUCT_VIEW']),
    },
    {
      name: 'Tồn ảo sản phẩm',
      icon: 'menu-icon fas fa-tools',
      route: '/virtual-stock',
      show: checkPermissions(['VIRTUAL_STOCK_VIEW']),
    },
  ],
  STORE: [
    {
      name: 'Cửa hàng',
      icon: 'menu-icon fas fa-landmark',
      route: '#',
      show: checkPermission('STORE_VIEW'),
      submenu: [
        {
          name: 'Cửa hàng',
          icon: 'menu-icon fas fa-store',
          route: '/stores',
          show: checkPermission('STORE_VIEW'),
        },
        {
          name: 'Doanh nghiệp',
          icon: 'menu-icon fas fa-building',
          route: '/companies',
          show: checkPermission('COMPANY_VIEW'),
        },
        {
          name: 'Khu vực',
          icon: 'menu-icon fas fa-chart-area',
          route: '/area',
          show: checkPermission('AREA_VIEW'),
        },
        {
          name: 'Vùng',
          icon: 'menu-icon fas fa-globe',
          route: '/region',
          show: checkPermission('REGION_VIEW'),
        },
      ],
    },
    {
      name: 'Phiếu chuyển cửa hàng',
      icon: 'menu-icon fas fa-store',
      route: '/transfer-store-forms',
      show: checkPermission('TRANSFER_STORE_FORM_VIEW'),
    },
    {
      name: 'Truy thu tồn kho',
      icon: 'menu-icon fas fa-file-invoice-dollar',
      route: '/stock-debts',
      show: checkPermission('DEBT_STOCK_VIEW'),
    },
    {
      name: 'Khuyến mãi',
      icon: 'menu-icon flaticon2-gift',
      route: '#',
      show: checkPermission('POINT_VIEW'),
      submenu: [
        {
          name: 'Điểm tích lũy',
          icon: 'menu-icon flaticon2-group',
          route: '/points',
          show: checkPermission('POINT_VIEW'),
        },
        {
          name: 'Coupon',
          icon: 'menu-icon fas fa-ticket-alt',
          route: '/coupon',
          show: checkPermission('COUPON_VIEW'),
        },
        {
          name: 'Voucher sản phẩm',
          icon: 'menu-icon fas fa-ticket-alt',
          route: '/voucher',
          show: checkPermission('COUPON_VIEW'),
        },
      ],
    },
    {
      name: 'Ngành hàng',
      icon: 'menu-icon fas fa-gifts',
      route: '#',
      show: checkPermission('POINT_VIEW'),
      submenu: [
        {
          name: 'Bộ phụ kiện tặng kèm',
          icon: 'menu-icon fas fa-gift',
          route: '/accessories',
          show: checkPermissions(['PRODUCT_ATTACH_VIEW']),
        },
        {
          name: 'Bộ phụ kiện khuyến mãi',
          icon: 'menu-icon fas fa-tags',
          route: '/promotion-accessories',
          show: checkPermissions(['PRODUCT_PROMOTION_VIEW']),
        },
        {
          name: 'Gói bảo hành',
          icon: 'menu-icon fas fa-award',
          route: '/warranty-packages',
          show: checkPermissions(['WARRANTY_VIEW']),
        },
        {
          name: 'Chiết khấu',
          icon: 'menu-icon fas fa-arrow-down',
          route: '/discount-programs',
          show: checkPermissions(['DISCOUNT_VIEW']),
        },
        {
          name: 'Chương trình trả góp',
          icon: 'menu-icon flaticon2-supermarket',
          route: '/installment-program',
          show: true,
        },
        {
          name: 'Bộ combo',
          icon: 'menu-icon flaticon2-supermarket',
          route: '/flexible-combo',
          show: true,
        },
        {
          name: 'Cấu hình web gateway',
          icon: 'menu-icon flaticon-network',
          route: '/web-partners',
          // @TODO: Add permissions 'CONFIG_WEB_PARTNERS'
          show: true,
        },
        {
          name: 'Cấu hình kích hoạt IMEI',
          icon: 'menu-icon flaticon2-shield',
          route: '/active-imei-config',
          show: checkPermissions(['IMEI_ACTIVE_REPORT_VIEW']),
        },
        {
          name: 'Quản lý deal sheet',
          icon: 'menu-icon flaticon2-supermarket',
          route: '/dealsheets',
          show: checkPermissions(['DEALSHEET_VIEW']),
        },
        {
          name: 'Giỏ hàng ưu tiên',
          icon: 'menu-icon flaticon2-supermarket',
          route: '/priority-cart',
          show: checkPermission('PRIORITY_CART_VIEW'),
        },
        {
          name: 'Thu thập thông tin sản phẩm',
          icon: 'menu-icon flaticon-tool',
          route: '/crawl-product',
          // @TODO: Add permissions 'CONFIG_WEB_PARTNERS'
          show: true,
        },
      ],
    },
    {
      name: 'Chương trình đặt trước',
      icon: 'menu-icon flaticon2-supermarket',
      route: '/pre-order-form',
      show: true,
    },
    {
      name: 'Đồng bộ dữ liệu CMS',
      icon: 'menu-icon flaticon2-supermarket',
      route: '#',
      show: true,
      submenu: [
        {
          name: 'Trạng thái đồng bộ',
          icon: 'menu-icon fas fa-gift',
          route: '/cms-sync',
          show: true,
        },
        {
          name: 'Cấu hình đồng bộ',
          icon: 'menu-icon fas fa-tags',
          route: '/cms-sync/config',
          show: true,
        },
      ],
    },
    {
      name: 'Cài đặt',
      icon: 'menu-icon flaticon2-settings',
      route: '/settings',
      show: checkPermission('USER_VIEW'),
    },
    {
      name: 'Tài khoản',
      icon: 'menu-icon fas fa-street-view',
      route: '#',
      show: checkPermission('EMPLOYEE_VIEW'),
      submenu: [
        {
          name: 'Nhân sự',
          icon: 'menu-icon fas fa-people-arrows',
          route: '/employees',
          show: checkPermission('EMPLOYEE_VIEW'),
        },
        {
          name: 'Tài khoản',
          icon: 'menu-icon flaticon2-user',
          route: '/users',
          show: checkPermission('USER_VIEW'),
        },
        {
          name: 'Phân quyền',
          icon: 'menu-icon fas fa-user-cog',
          route: '/permissions',
          show: checkPermission('ROLE_VIEW'),
        },
        {
          name: 'Cấu hình ca làm việc',
          icon: 'menu-icon fas fa-clipboard',
          route: '/shiftWorkConfig',
          show: checkPermission('SHIFTWORK_CONFIG_VIEW'),
        },
        {
          name: 'Bộ phận/ phòng ban',
          icon: 'menu-icon fas fa-sitemap',
          route: '/departments',
          show: checkPermission('DEPARTMENT_VIEW'),
        },
      ],
    },
    {
      name: 'Cài đặt power bi',
      icon: 'menu-icon flaticon2-settings',
      route: '/power-bi',
      show: checkPermission('USER_VIEW'),
    },
    {
      name: 'Cài đặt nhà phát triển',
      icon: 'menu-icon flaticon2-settings',
      route: '/development-tools',
      show: true,
    },
    {
      name: 'Cài đặt ca làm việc',
      icon: 'menu-icon flaticon2-settings',
      route: '/shift-work',
      show: checkPermission('SHIFTWORK_VIEW'),
    },
    {
      name: 'Thông báo Loyalty',
      icon: 'menu-icon flaticon2-settings',
      route: '/loyalty-notifies',
      show: true,
    },
    {
      name: 'Cấu hình duyệt chi',
      icon: 'menu-icon flaticon2-settings',
      route: '/approval-configs',
      show: checkPermission('APPROVAL_CONFIG_VIEW'),
    },
    {
      name: 'Credentials',
      icon: 'menu-icon flaticon2-settings',
      route: '/app-credentials',
      show: true,
    },
    {
      name: 'Lý do bảo hành',
      icon: 'menu-icon flaticon2-settings',
      route: '/warranty-reason-config',
      show: checkPermission('WARRANTY_REASON_CONFIG_VIEW'),
    },
    {
      name: 'Cấu hình truy cập',
      icon: 'menu-icon flaticon2-settings',
      route: '/access-settings',
      show: checkPermission('USER_ACCESS_VIEW'),
    },
    {
      name: 'Cấu hình giờ công',
      icon: 'menu-icon flaticon2-settings',
      route: '/working-hour-config',
      show: true,
    },
    {
      name: 'Telegram',
      icon: 'menu-icon flaticon2-settings',
      route: '/telegram-chatbot-config',
      show: true,
    },
    {
      name: 'Chi phí vận chuyển',
      icon: 'menu-icon flaticon2-settings',
      route: '/shipping-config',
      show: true,
    },
  ],
  TRADE_IN: [
    {
      name: 'Phiếu định giá',
      icon: 'menu-icon far fa-file-alt',
      route: '/trade-in',
      show: checkPermission('TRADE_IN_VIEW'),
    },
    {
      name: 'Phiếu thu cũ',
      icon: 'menu-icon far fa-file-alt',
      route: '/import-trade-in',
      show: checkPermission('TRADE_IN_VIEW'),
    },
    {
      name: 'Chương trình thu cũ- đổi mới',
      icon: 'menu-icon fas fa-tools',
      route: '/tradein-programs',
      show: checkPermission('TRADE_IN_PROGRAM_VIEW'),
    },
    {
      name: 'Danh mục linh kiện',
      icon: 'menu-icon fas fa-tools',
      route: '/product-component-categories',
      show: checkPermission('TRADE_IN_PROGRAM_VIEW'),
    },
    {
      name: 'Linh kiện',
      icon: 'menu-icon fas fa-tools',
      route: '/product-component',
      show: checkPermission('TRADE_IN_PROGRAM_VIEW'),
    },
    {
      name: 'Nhóm sản phẩm linh kiện',
      icon: 'menu-icon fas fa-tools',
      route: '/product-component-group',
      show: checkPermission('TRADE_IN_PROGRAM_VIEW'),
    },
    {
      name: 'Phiếu định giá đã xoá',
      icon: 'menu-icon far fa-file-alt',
      route: '/deleted-trade-in',
      show: checkPermission('TRADE_IN_VIEW'),
    },
  ],
  STOCK: [
    {
      name: 'Phiếu yêu cầu nhập hàng',
      icon: 'menu-icon flaticon2-supermarket',
      route: '#',
      show: checkPermission('PO_VIEW') || checkPermission('PO_IMPORT_STOCK'),
      submenu: [
        {
          name: 'Phiếu yêu cầu',
          icon: 'menu-icon flaticon2-supermarket',
          route: '/purchase-orders',
          show: checkPermission('PO_VIEW'),
        },
        {
          name: 'Phiếu nhập hàng',
          icon: 'menu-icon flaticon2-delivery-package',
          route: '/import-purchase-orders',
          show: checkPermission('PO_IMPORT_STOCK'),
        },
      ],
    },
    {
      name: 'Nhập kho',
      icon: 'menu-icon flaticon2-supermarket',
      route: '/stocks',
      show: checkPermission('STOCK_VIEW'),
    },
    {
      name: 'Xuất kho',
      icon: 'menu-icon flaticon2-delivery-package',
      route: '/export-stocks',
      show: checkPermission('STOCK_VIEW'),
    },
    {
      name: 'Phiếu kho bảo hành',
      icon: 'menu-icon flaticon2-delivery-package',
      route: '/import-warranty-stocks',
      show: checkPermission('STOCK_VIEW'),
      submenu: [
        {
          name: 'Nhập kho bảo hành',
          icon: 'menu-icon flaticon2-supermarket',
          route: '/import-warranty-stocks',
          show: checkPermission('STOCK_VIEW'),
        },
        {
          name: 'Xuất kho bảo hành',
          icon: 'menu-icon flaticon2-delivery-package',
          route: '/export-warranty-stocks',
          show: checkPermission('STOCK_VIEW'),
        },
      ],
    },
    {
      name: 'Phiếu kho kiểm tồn',
      icon: 'menu-icon flaticon2-delivery-package',
      route: '/import-check-sheet-stocks',
      show: checkPermission('STOCK_VIEW'),
      submenu: [
        {
          name: 'Nhập kho từ phiếu kiểm tồn',
          icon: 'menu-icon flaticon2-supermarket',
          route: '/import-check-sheet-stocks',
          show: checkPermission('STOCK_VIEW'),
        },
        {
          name: 'Xuất kho từ phiếu kiểm tồn',
          icon: 'menu-icon flaticon2-delivery-package',
          route: '/export-check-sheet-stocks',
          show: checkPermission('STOCK_VIEW'),
        },
      ],
    },
    {
      name: 'Phiếu cho mượn máy',
      icon: 'menu-icon flaticon2-supermarket',
      route: '/lending-stock',
      show: checkPermission('STOCK_VIEW'),
    },
    {
      name: 'Sản phẩm nhập xuất kho',
      icon: 'menu-icon flaticon2-supermarket',
      route: '/product-stockslip',
      show: checkPermission('STOCK_VIEW'),
    },
    {
      name: 'Phiếu yêu cầu XNK',
      icon: 'menu-icon flaticon2-delivery-package',
      route: '/require-stocks',
      show: checkPermission('STOCK_VIEW'),
      submenu: [
        {
          name: 'Phiếu yêu cầu XNK',
          icon: 'menu-icon fas fa-store',
          route: '/require-stocks',
          show: true,
        },
        {
          name: 'Sản phẩm yêu cầu XNK',
          icon: 'menu-icon fas fa-money-bill-alt',
          route: '/product-require-stocks',
          show: true,
        },
        {
          name: 'Phiếu yêu cầu XNK BH',
          icon: 'menu-icon fas fa-warehouse',
          route: '/require-warranty-stocks',
          show: true,
        },
      ],
    },
    {
      name: 'Chuyển kho',
      icon: 'menu-icon flaticon2-paperplane',
      route: '/transfer-stocks',
      show: checkPermission('STOCK_VIEW'),
    },
    {
      name: 'Phiếu chuyển kho đã xóa',
      icon: 'menu-icon flaticon2-paperplane',
      route: '/transfer-stock-deleted',
      show: checkPermission('STOCK_VIEW'),
    },
    {
      name: 'Phiếu hoàn tất bảo hành',
      icon: 'menu-icon fa fa-clipboard-check',
      route: '/complete-warranty-stock',
      show: checkPermission('STOCK_VIEW'),
    },
    {
      name: 'Cân kho',
      icon: 'menu-icon flaticon2-paperplane',
      route: '#',
      show: checkPermission('INVENTORY_BALANCE'),
      submenu: [
        {
          name: 'Cân kho sản phẩm',
          icon: 'menu-icon fas fa-store',
          route: '/inventory-balance',
          show: true,
        },
        {
          name: 'Cân kho IMEI',
          icon: 'menu-icon fas fa-store',
          route: '/imei-balance',
          show: true,
        },
      ],
    },
    {
      name: 'Tool',
      icon: 'menu-icon flaticon2-delivery-package',
      route: '#',
      show: checkPermission('INVENTORY_BALANCE'),
      submenu: [
        {
          name: 'Cronjob',
          icon: 'menu-icon flaticon-calendar-with-a-clock-time-tools',
          route: '/tools/list',
          show: true,
        },
        {
          name: 'Đồng bộ sản phẩm',
          icon: 'menu-icon flaticon2-crisp-icons',
          route: '/tools/sync-product',
          show: true,
        },
        {
          name: 'Đồng bộ hoá đơn',
          icon: 'menu-icon flaticon2-crisp-icons',
          route: '/tools/sync-bill',
          show: true,
        },
        {
          name: 'Đồng bộ imei',
          icon: 'menu-icon flaticon2-crisp-icons',
          route: '/tools/sync-imei',
          show: true,
        },
      ],
    },
    {
      name: 'Quản lý vận chuyển',
      icon: 'menu-icon flaticon2-crisp-icons',
      route: '/shipment',
      show: checkPermissions(['SHIPMENT_VIEW']),
    },
  ],
  ORDER: [
    {
      name: 'Đơn hàng',
      icon: 'menu-icon flaticon2-supermarket',
      route: '/orders',
      show: checkPermission('STOCK_VIEW'),
    },
    {
      name: 'Đơn đặt trước',
      icon: 'menu-icon flaticon2-supermarket',
      route: '/pre-order',
      show: true,
    },
    {
      name: 'Nguồn đơn hàng',
      icon: 'menu-icon flaticon2-delivery-package',
      route: '/order-source',
      show: checkPermission('ORDER_SOURCE_VIEW'),
    },
    {
      name: 'Lịch sử',
      icon: 'menu-icon fas fa-history',
      route: '/order-history',
      show: true,
    },
  ],
  BILL: [
    {
      name: 'Hóa đơn bán lẻ',
      icon: 'menu-icon far fa-file-alt',
      route: '/bills',
      show: checkPermission('BILL_VIEW'),
    },
    {
      name: 'Hóa đơn bán B2B',
      icon: 'menu-icon fas fa-file-alt',
      route: '/wholesaleInvoices',
      show: checkPermission('BILL_VIEW'),
    },
    {
      name: 'Trả hàng',
      icon: 'menu-icon flaticon-reply',
      route: '/returnBills',
      show: checkPermission('BILL_VIEW'),
    },
    {
      name: 'Hóa đơn đã xóa',
      icon: 'menu-icon far fa-file-alt',
      route: '/bill-deleted',
      show: checkPermission('BILL_VIEW'),
    },
    {
      name: 'Lịch sử',
      icon: 'menu-icon fas fa-history',
      route: '/bill-history',
      show: checkPermission('BILL_VIEW'),
    },
    {
      name: 'Hoá đơn đỏ',
      icon: 'menu-icon fas fa-receipt',
      route: '/vat-invoice-v2',
      show: true,
    },
    {
      name: 'Phiếu yêu cầu bảo hành',
      icon: 'menu-icon flaticon-security',
      route: '/warranty-request',
      show: checkPermission('BILL_VIEW'),
    },
    {
      name: 'Hóa đơn bảo hành',
      icon: 'menu-icon fas fa-receipt',
      route: '/warranty-bill',
      show: checkPermission('BILL_VIEW'),
    },
    {
      name: 'Sổ tay',
      icon: 'menu-icon far fa-file-alt',
      route: '/note-books',
      show: true,
    },
  ],
  MBF: [
    {
      name: 'Hoá đơn mobifone',
      icon: 'menu-icon far fa-file-alt',
      route: '/mbf-transaction',
      show: checkPermission('BILL_VIEW'),
    },
    {
      name: 'Hoá đơn doanh thu',
      icon: 'menu-icon far fa-file-alt',
      route: '/bill-convert',
      show: checkPermission('BILL_VIEW'),
    },
    {
      name: 'Sản phẩm mobifone',
      icon: 'menu-icon flaticon-tool',
      route: '/mbf-product',
      show: checkPermission('BILL_VIEW'),
    },
  ],
  POWERBI: [
    {
      name: 'PowerBi',
      icon: 'menu-icon far fa-file-alt',
      route: '/dashboard',
      show: true,
    },
  ],
  REWARD: [
    {
      name: 'Hoa hồng',
      icon: 'menu-icon fas fa-gift',
      route: '#',
      show: checkPermission('REWARD_VIEW'),
      submenu: [
        {
          name: 'Nhóm sản phẩm',
          icon: 'menu-icon fas fa-store',
          route: '/reward/reward-category',
          show: true,
        },
        {
          name: 'Chương trình thưởng hoa hồng ngành cửa hàng',
          icon: 'menu-icon fas fa-money-bill-alt',
          route: '/reward/reward-program',
          show: true,
        },
        {
          name: 'Cộng trừ khác hoa hồng cửa hàng',
          icon: 'menu-icon fas fa-store',
          route: '/reward/reward-detail',
          show: true,
        },
        {
          name: 'Chương trình thưởng hoa hồng Ngành hàng phụ kiện',
          icon: 'menu-icon fas fa-money-bill-alt',
          route: '/reward/reward-accessory-program',
          show: true,
        },
        {
          name: 'Cộng trừ khác hoa hồng ngành hàng phụ kiện',
          icon: 'menu-icon fas fa-store',
          route: '/reward/reward-accessory-detail',
          show: true,
        },
        {
          name: 'Chi phí ngành hàng phụ kiện',
          icon: 'menu-icon fas fa-money-bill-alt',
          route: '/reward/assessory-industry-expense',
          show: true,
        },
        {
          name:
            'Chương trình thưởng hoa hồng ngành hàng điện thoại (Theo Tháng)',
          icon: 'menu-icon fas fa-money-bill-alt',
          route: '/reward/reward-month-phone-program',
          show: true,
        },
      ],
    },
    {
      name: 'Thưởng NHPK',
      icon: 'menu-icon fas fa-magic',
      route: '#',
      show: true,
      submenu: [
        {
          name: 'Chương trình',
          icon: 'menu-icon far fa-file-alt',
          route: '/reward-accessory',
          show: true,
        },
        {
          name: 'Báo cáo',
          icon: 'menu-icon far fa-file-alt',
          route: '/reward-accessory-report',
          show: true,
        },
      ],
    },
    {
      name: 'Thưởng ngành hàng máy',
      icon: 'menu-icon fas fa-tablet-alt',
      route: '#',
      show: true,
      submenu: [
        {
          name: 'Chương trình',
          icon: 'menu-icon far fa-file-alt',
          route: '/reward-phone',
          show: true,
        },
        {
          name: 'Báo cáo',
          icon: 'menu-icon far fa-file-alt',
          route: '/reward-phone-report',
          show: true,
        },
      ],
    },
    {
      name: 'Công nợ nhân viên',
      icon: 'menu-icon fas fa-file-signature',
      route: '/employee-debt',
      show: checkPermission('SALARY_REPORT'),
    },
    {
      name: 'Bảng lương import',
      icon: 'menu-icon fa fa-money-bill-wave',
      route: '/human-resources',
      show: checkPermission('SALARY_REPORT'),
    },
    {
      name: 'Cài đặt bảng lương',
      icon: 'menu-icon flaticon2-settings',
      route: '#',
      show: checkPermission('SALARY_REPORT'),
      submenu: [
        {
          name: 'Thuế TNCN',
          icon: 'menu-icon fas fa-people-arrows',
          route: '/tax-configs',
          show: checkPermission('SALARY_REPORT'),
        },
        {
          name: 'Phụ cấp chức vụ',
          icon: 'menu-icon flaticon2-user',
          route: '/allowance-configs',
          show: checkPermission('SALARY_REPORT'),
        },
        {
          name: 'Phát sinh lương',
          icon: 'menu-icon flaticon2-user',
          route: '/salary-incurred-configs',
          show: checkPermission('SALARY_REPORT'),
        },
      ],
    },
    {
      name: 'Báo cáo bảng lương',
      icon: 'menu-icon fa fa-dollar',
      route: '/salary-reports',
      show: checkPermission('SALARY_REPORT'),
    },
  ],
  CUSTOMER: [
    {
      name: 'Khách hàng',
      icon: 'menu-icon flaticon2-group',
      route: '/customers',
      show: checkPermission('CUSTOMER_VIEW'),
    },
    {
      name: 'Khách hàng doanh nghiệp',
      icon: 'menu-icon flaticon2-group',
      route: '/businesses',
      show: checkPermission('CUSTOMER_VIEW'),
    },
    {
      name: 'Chăm sóc khách hàng',
      icon: 'menu-icon flaticon2-group',
      route: '/customer-care',
      show: checkPermission('CUSTOMER_CARE_VIEW'),
    },
    {
      name: 'Hình thức chăm sóc',
      icon: 'menu-icon flaticon2-group',
      route: '/customer-care-form',
      show: checkPermission('CUSTOMER_CARE_VIEW'),
    },
    {
      name: 'Cấu hình phân lọai khách hàng',
      icon: 'menu-icon flaticon2-group',
      route: '/customer-type',
      show: checkPermission('CUSTOMER_CARE_VIEW'),
    },
    {
      name: 'Phiếu chuyển điểm tiêu dùng',
      icon: 'menu-icon flaticon2-group',
      route: '#',
      show: true,
      submenu: [
        {
          name: 'Danh sách',
          icon: 'menu-icon flaticon2-group',
          route: '/customer-transfer',
          show: true,
        },
        {
          name: 'Thống kê',
          icon: 'menu-icon flaticon2-group',
          route: '/customer-transfer-statistical',
          show: checkPermissions(['POINT_TRANSTER_VIEW_ALL']),
        },
      ],
    },
  ],
};
